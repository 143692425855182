* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/Montserrat-ExtraLight.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Druk Wide";
  src: url(../fonts/Druk\ Wide\ Bold.ttf);
  font-size: normal;
  font-weight: bold;
}

/*Estilos Generales d la pagina*/
h2,
h3 {
  text-align: justify;
  font-size: 130px;
  letter-spacing: 5px;
  font-family: "Druk Wide";
  margin: 0;
}

body {
  background-color: black;
  color: white;
}

p {
  font-family: "Montserrat";
  line-height: 2px;
  font-size: 21px;
  line-height: normal;
  width: 100%;
  letter-spacing: 2px;
  text-align: justify;
}

.Banner {
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-size: 1.5rem;
  display: flex;
  font-family: "Druk Wide";
  position: fixed;
  width: 100%;
  letter-spacing: 2px;
  z-index: 1;
}

.Bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 20%;
}

.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15% 15%;
}

/*Menú Hamburguesa */
.bm-burger-button {
  position: fixed;
  width: 55px;
  height: 20px;
  right: 56px;
  top: 56px;
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-burger-bars-hover {
  background: #bea9a9;
}

.bm-cross-button {
  height: 4px;
  width: 44px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.3em;
  opacity: 1;
}

.bm-morph-shape {
  fill: #ffffff;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-family: "Druk Wide";
}

.bm-item:hover {
  color: #ffffff;
  font-weight: bold;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  margin-top: -10px;
}

.floating-div {
  display: none;
  background: rgba(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Adjust as needed */
}

.floating-div.visible {
  display: flex;
}

/*Lista d videos*/
.video-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5rem;
}

.video-list {
  position: relative;
  margin: 2.7rem;
  border-radius: 3rem;
  overflow: hidden;
  width: 65vh;
  height: 35vh;
}

.video-thumbnail {
  width: 100%;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    26,
    19,
    19,
    0.87
  ); /* Fondo blanco translúcido más oscuro */
  border-radius: 3rem;
  opacity: 0; /* Inicialmente oculto */
  transition: opacity 0.2s; /* Agrega una transición suave */
}

.video-list:hover .video-overlay {
  opacity: 1; /* Muestra el fondo traslúcido cuando el cursor está sobre el video */
}

.video-title {
  color: #fff;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  font-family: "Druk Wide";
  opacity: 1; /* Asegura que el título sea completamente visible */
  transition: opacity 0.2s; /* Agrega una transición suave */
}

.video-list:hover .video-title {
  opacity: 1; /* Vuelve el título translúcido cuando el cursor está sobre el video */
}

.eempty {
  height: 0px;
}

@media screen and (max-width: 820px) {
  .wololo {
    margin-left: -50%;
  }

  .Banner {
    justify-content: left;
    margin-top: 5px;
    font-size: 0.7rem;
    margin-left: 5px;

    letter-spacing: 2px;
    z-index: 1;
  }

  .bm-item-list {
    color: #000;
    padding: 0.8em;
    margin-top: 150px;
    overflow-y: hidden;
    overflow: hidden;
    height: 95%;
  }

  .bm-item {
    display: inline-block;
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: solid;
    overflow-y: hidden;
    overflow: hidden;
  }

  .bm-menu {
    background: #000;
    padding: 0 0 0;
    font-size: 1.15em;
    opacity: 1;
    width: 100%;
    overflow-y: hidden;
    overflow: hidden;
  }

  .bm-menu-wrap {
    overflow-y: hidden;
    overflow: hidden;
  }

  .Bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 5%;
  }

  .eempty {
    height: 80px;
  }

  h2 {
    text-align: justify;
    font-size: 80px;
    letter-spacing: 5px;
    font-family: "Druk Wide";
    margin: 0;
  }

  h3 {
    text-align: justify;
    font-size: 40px;
    letter-spacing: 5px;
    font-family: "Druk Wide";
    margin: 0;
  }

  .video-container {
    padding: 0.5rem;
  }

  .video-list {
    margin: 1rem;
    height: 8rem;
  }

  .video-thumbnail {
    width: 100%;
    height: auto;
  }

  .bm-burger-button {
    margin-top: -28px;
    width: 35px;
    right: 20px;
  }
}
